<i18n>
ru:
  addressDelivery: 'Адрес доставки: '
  addressInHall: 'Адрес ресторана: '
  addressSelfService: 'Адрес самовывоза: '
  averageTimeWarning: 'Ваш заказ будет выполнен в течение '
  deliveryASAPLabel: Как можно скорее
  deliveryTime: '{date} к {time}'
  deliveryTimeGeneral: 'Вы хотите получить свой заказ '
  deliveryTimeRanged: '{date} с {start} до {end}'
  deliveryTimeToday: 'сегодня к {time}'
  deliveryTimeTodayRanged: 'сегодня с {start} до {end}'
  goToMenu: Вернуться в меню
  notFound: Заказ не найден!
  orderError: 'Пожалуйста, повторите отправку заказа'
  orderInfo: Информация о заказе
  orderNotIntegrated: >-
    Ваш заказ не попал в систему, но не переживайте, мы всё равно его увидим и
    обработаем в ближайшее время!
  orderStatus: 'Статус заказа: '
  printOrder: Распечатать заказ
  thanksForOrder: Спасибо за заказ!
  thanksForOrderNum: 'Спасибо за заказ №{num}!'
  totalPaid: 'Итого:'
  waitForOrder: 'Пожалуйста, подождите'
  waitForOrderNum: 'Пожалуйста, подождите (№{num})'
  warning: Уважаемые клиенты!
  warningText: ''
  warningTextCallback: ''
  warningTextDelivery: ''
  warningTextNoCallback: ''
  warningTextSelfService: ''
  tips: Оставить чаевые
ua:
  addressDelivery: 'Адреса доставки: '
  addressInHall: 'Адреса ресторану: '
  addressSelfService: 'Адреса самовивозу: '
  averageTimeWarning: 'Ваше замовлення буде виконано протягом '
  deliveryASAPLabel: На найближчий час
  deliveryTime: '{date} до {time}'
  deliveryTimeGeneral: 'Ви хочете отримати своє замовлення '
  deliveryTimeRanged: '{date} з {start} до {end}'
  deliveryTimeToday: 'сьогодні до {time}'
  deliveryTimeTodayRanged: 'сьогодні з {start} до {end}'
  goToMenu: Повернутися в меню
  notFound: Замовлення не знайдено!
  orderError: 'Будь ласка, повторіть відправку замовлення'
  orderInfo: Iнформація про замовлення
  orderNotIntegrated: >-
    Ваше замовлення не потрапив в систему, але не переживайте, ми все одно його
    побачимо і опрацюємо найближчим часом!
  orderStatus: 'Статус замовлення: '
  printOrder: Роздрукувати замовлення
  thanksForOrder: Дякуємо за замовлення!
  thanksForOrderNum: 'Дякуємо за замовлення №{num}!'
  totalPaid: 'Загалом:'
  waitForOrder: 'Будь ласка, зачекайте'
  waitForOrderNum: 'Будь ласка, зачекайте (№{num})'
  warning: Шановні клієнти!
  warningText: ''
  warningTextCallback: ''
  warningTextDelivery: ''
  warningTextNoCallback: ''
  warningTextSelfService: ''
  tips: Залиште чайові
us:
  addressDelivery: 'Delivery address: '
  addressInHall: 'Restaurant address: '
  addressSelfService: 'Pickup address: '
  averageTimeWarning: 'Your order will be completed in '
  deliveryASAPLabel: As soon as possible
  deliveryTime: '{date} at {time}'
  deliveryTimeGeneral: 'Your order will be ready '
  deliveryTimeRanged: '{date} from {start} to {end}'
  deliveryTimeToday: 'today at {time}'
  deliveryTimeTodayRanged: 'today from {start} to {end}'
  goToMenu: Back to menu
  notFound: Order not found!
  orderError: Please resend your order
  orderInfo: Order information
  orderNotIntegrated: >-
    Your order was not accepted by the system, but do not worry, our operators
    will receive it anyway
  orderStatus: 'Order status: '
  printOrder: Print order
  thanksForOrder: Thank you for your order!
  thanksForOrderNum: 'Thank you for your order #{num}!'
  totalPaid: 'Total:'
  waitForOrder: Please wait
  waitForOrderNum: 'Please wait (#{num})'
  warning: Dear customers!
  warningText: ''
  warningTextCallback: ''
  warningTextDelivery: ''
  warningTextNoCallback: ''
  warningTextSelfService: ''
  tips: Leave a tip
</i18n>

<template>
  <div
    v-if="!loaded"
    class="v-order-complete"
  >
    <arora-loader />
  </div>
  <div
    v-else-if="orderInfo"
    class="v-order-complete"
    :class="{
      'v-order-complete__column':
        appConfig.VueSettingsPreRun.OrderCompleteView === 'column'
    }"
  >
    <div class="v-order-complete-left">
      <div
        class="v-order-complete-card--main-first"
        :class="{
          'v-order-complete__column':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'column'
        }"
      >
        <div
          class="v-d-flex v-justify-content-between v-align-items-baseline v-flex-row v-flex-100"
        >
          <div
            class="v-order-complete-status v-d-flex v-flex-column"
            data-test-id="order-complete-status"
            v-show="!appConfig.VueSettingsPreRun.OrderCompleteHideStatus"
          >
            <span v-html="translate('orderComplete.orderStatus')" />
            <common-order-status :status="orderInfoStatus" />
          </div>
          <div class="v-d-flex">
            <arora-button
              v-if="appConfig.VueSettingsPreRun.OrderCompleteHidePrint"
              class-name="v-btn-link v-print-order"
              :label="translate('orderComplete.printOrder')"
              @click="print"
            >
              <common-popover
                :delay="100"
                placement="top"
              >
                <template #hoverable>
                  <span class="v-icon v-icon__18">
                    <icon-old-general-print />
                  </span>
                </template>
                <template #content
                  >{{ translate('orderComplete.printOrder') }}
                </template>
              </common-popover>
            </arora-button>
          </div>
        </div>
        <div
          class="v-order-complete-status-text"
          v-show="!appConfig.VueSettingsPreRun.OrderCompleteHideNumber"
        >
          <div
            v-if="orderStatusBlock === 'waiting'"
            v-html="
              orderInfoStatus.Number && orderInfoStatus.Number !== '0'
                ? translate('orderComplete.waitForOrderNum', {
                    num: orderInfoStatus.Number
                  })
                : translate('orderComplete.waitForOrder')
            "
          />
          <div
            v-else-if="orderStatusBlock === 'error'"
            v-html="translate('orderComplete.orderError')"
          />
          <div
            v-else-if="orderStatusBlock === 'success'"
            v-html="
              orderInfoStatus.Number && orderInfoStatus.Number !== '0'
                ? translate('orderComplete.thanksForOrderNum', {
                    num: orderInfoStatus.Number
                  })
                : translate('orderComplete.thanksForOrder')
            "
          />
          <div
            v-else-if="orderStatusBlock === 'not-integrated'"
            v-html="translate('orderComplete.orderNotIntegrated')"
          />
        </div>

        <div
          v-if="
            orderStatusBlock !== 'error' &&
            averageTimeWithDelayMs &&
            orderInfo.DeliveryTime.RightNow
          "
          class="v-order-complete-status-text"
        >
          <span
            class="v-mr-xxs"
            v-html="translate('orderComplete.averageTimeWarning')"
          />
          <common-time :time-ms="averageTimeWithDelayMs" />
        </div>

        <transition
          appear
          mode="out-in"
          name="slidedown"
        >
          <div
            v-if="!stringIsNullOrWhitespace(orderInfoStatus.TipsLink)"
            class="v-d-flex"
          >
            <arora-button
              class-name="v-btn v-btn-secondary v-btn-lg"
              ignore-settings
              :label="translate('orderComplete.tips')"
              @click="openTipsLink"
            />
          </div>
        </transition>
      </div>
      <div
        class="v-d-flex v-flex-row v-align-items-center v-mb-md"
        v-if="
          appConfig.RestaurantSettingsPreRun
            .NeedToDisplayDeliveryDriverCoordinates &&
          orderInfoStatus.Status <= OrderStatus.R_Delivering &&
          isSmall
        "
      >
        <span
          class="v-title__map-courier v-mr-xs v-font-weight-600"
          v-html="translate('mapsCourier.titleMapCourier')"
        />

        <common-popover
          :delay="100"
          placement="top"
          v-if="orderInfoStatus.Status < OrderStatus.R_Delivering"
        >
          <template #hoverable>
            <span class="v-icon v-icon__24 v-primary-color__svg">
              <icon-old-general-information-circle class="v-w-100 v-img-fluid" />
            </span>
          </template>
          <template #content
            >{{ translate('mapsCourier.helpForCourier') }}
          </template>
        </common-popover>
      </div>
      <maps-courier
        v-if="
          appConfig.RestaurantSettingsPreRun
            .NeedToDisplayDeliveryDriverCoordinates &&
          orderInfo?.Latitude &&
          orderInfo?.Longitude &&
          orderInfoStatus.Status <= OrderStatus.R_Delivering &&
          isSmall
        "
        :courier-latitude="orderInfoStatus.CourierLocation?.Latitude"
        :courier-longitude="orderInfoStatus.CourierLocation?.Longitude"
        :address-latitude="orderInfo?.Latitude"
        :address-longitude="orderInfo?.Longitude"
        :delivery-terminal="orderInfo?.TargetTerminalID"
      />
      <div
        v-if="warningText?.length > 0"
        class="v-order-complete-card v-order-complete-card-warning"
      >
        <div
          class="v-order-complete-status"
          v-html="translate('orderComplete.warning')"
        />
        <span
          class="v-order-complete-status-text"
          v-html="warningText"
        />
      </div>

      <div
        v-if="appConfig.VueSettingsPreRun.OrderCompleteShowApps"
        class="v-order-complete-card v-order-complete-card--apps"
        :style="
          orderCompleteAppsBackground
            ? `background-image:url(${orderCompleteAppsBackground})`
            : ''
        "
      >
        <common-apps
          hide-text
          in-one-line
        />
      </div>

      <div
        v-if="appConfig.VueSettingsPreRun.OrderCompleteShowSocial"
        class="v-order-complete-card"
        :class="{
          'v-order-complete-card--social':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'column',
          'v-order-complete-card--social-compact':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
        }"
        :style="
          orderCompleteSocialBackground
            ? `background-image:url(${orderCompleteSocialBackground})`
            : ''
        "
      >
        <common-social-networks />
      </div>

      <div
        v-if="appConfig.VueSettingsPreRun.OrderCompleteShowAdditional"
        class="v-order-complete-card"
        :class="{
          'v-order-complete-card--social':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'column',
          'v-order-complete-card--social-compact':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
        }"
        :style="
          orderCompleteAdditionalBackground
            ? `background-image:url(${orderCompleteAdditionalBackground})`
            : ''
        "
      />

      <div class="v-order-complete-card">
        <div
          class="v-order-complete-info-title"
          v-html="translate('orderComplete.orderInfo')"
        />

        <div class="v-order-complete-info-personal">
          <span
            class="v-order-complete-info-description"
            v-html="`${translate('personalInfo.name')}:`"
          />
          <span
            class="v-order-complete-info-name"
            v-html="sanitize(orderInfo.ClientName)"
          />
        </div>
        <div class="v-order-complete-info-personal">
          <span
            class="v-order-complete-info-description"
            v-html="`${translate('personalInfo.phoneNumber')}:`"
          />
          <span v-html="phoneBeautified(orderInfo.ClientPhone)" />
        </div>
        <div
          v-if="orderInfo.ClientEmail && orderInfo.ClientEmail !== ''"
          class="v-order-complete-info-personal"
        >
          <span
            class="v-order-complete-info-description"
            v-html="`${translate('personalInfo.email')}:`"
          />
          <span v-html="orderInfo.ClientEmail" />
        </div>
        <div class="v-order-complete-info-personal">
          <span
            class="v-order-complete-info-description"
            v-html="`${translate('personalInfo.personCount')}:`"
          />
          <span v-html="numeric(orderInfo.PersonCount)" />
        </div>
        <div class="v-order-complete-info">
          <span
            v-if="isDelivery"
            class="v-order-complete-info-description"
            v-html="translate('orderComplete.addressDelivery')"
          />
          <span
            v-else-if="isSelfService"
            class="v-order-complete-info-description"
            v-html="translate('orderComplete.addressSelfService')"
          />
          <span
            v-else-if="isInHall"
            class="v-order-complete-info-description"
            v-html="translate('orderComplete.addressInHall')"
          />
          <address-delivery
            v-if="isDelivery"
            :address="orderInfo.Address"
          />
          <address-from-terminal
            v-else
            :terminal-id="orderInfo.TargetTerminalID"
          />
        </div>
        <div class="v-order-complete-info">
          <span
            class="v-order-complete-info-description"
            v-html="`${translate('orderComplete.deliveryTimeGeneral')} `"
          />
          <span
            v-html="
              orderInfo.DeliveryTime.RightNow
                ? translate('orderComplete.deliveryASAPLabel').toLowerCase()
                : deliveryTime
            "
          />
        </div>
        <div class="v-order-complete-info">
          <span
            class="v-order-complete-info-description"
            v-html="`${translate('comment.comment')}:`"
          />
          <span v-html="orderInfo.Comment" />
        </div>
      </div>
    </div>
    <div class="v-order-complete-right">
      <div class="v-order-complete-items-wrapper">
        <cart-entwash-order-complete-items :items="orderInfo.Items" />
      </div>

      <div
        class="v-order-complete-card-main-second"
        :class="{
          'v-order-complete__column':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'column'
        }"
      >
        <ul>
          <cart-entwash-total-summary-single
            :amount="orderInfo.Sum"
            :text="translate('totalSummaryPage.cartSum')"
          />
          <cart-entwash-total-summary-single
            v-if="appConfig.RestaurantSettingsPreRun.ServiceFeeEnabled"
            :amount="orderInfo.ServiceFeeCost"
            :text="translate('totalSummaryPage.serviceFee')"
          />
          <cart-entwash-total-summary-single
            v-if="
              (appConfig.RestaurantSettingsPreRun.PriorityServiceDeliveryEnabled &&
                clientStore.courierDelivery) ||
              (appConfig.RestaurantSettingsPreRun.PriorityServicePickupEnabled &&
                clientStore.selfService)
            "
            :amount="orderInfo.PriorityServiceCost"
            :text="translate('totalSummaryPage.priorityCooking')"
          />
          <cart-entwash-total-summary-single
            v-if="orderInfo.DeliverySum > 0"
            :amount="orderInfo.DeliverySum"
            :text="translate('totalSummaryPage.delivery')"
            sign="+"
          />
          <cart-entwash-total-summary-single
            v-if="orderInfo.Sum - orderInfo.SumWithDiscount > 0"
            :amount="orderInfo.Sum - orderInfo.SumWithDiscount"
            :text="translate('totalSummaryPage.discount')"
            sign="-"
          />
          <cart-entwash-total-summary-single
            v-if="orderInfo.Tax > 0"
            :amount="orderInfo.Tax"
            :text="translate('totalSummaryPage.tax')"
            sign="+"
          />
          <cart-entwash-total-summary-single
            v-if="orderInfo.Gratuity > 0"
            :amount="orderInfo.Gratuity"
            :text="translate('totalSummaryPage.tip')"
            sign="+"
          />
          <cart-entwash-total-summary-single
            v-if="orderInfo.Points && pointsWithdraw"
            bonus-withdraw
            :amount="pointsWithdraw"
            :text="translate('totalSummaryPage.bonusWithdraw')"
            sign="-"
          />
          <cart-entwash-total-summary-single
            v-if="orderInfo.Points && pointsAccrual"
            bonus-accrual
            :amount="pointsAccrual"
            :text="translate('totalSummaryPage.bonusAccrual')"
            sign="+"
          />

          <cart-entwash-total-summary-single
            :amount="orderInfo.SumTotal"
            :text="translate('orderComplete.totalPaid')"
          />
        </ul>
      </div>
    </div>
  </div>
  <div
    v-else
    class="v-order-complete-card--main-first"
  >
    <div
      class="v-order-complete-status"
      v-html="translate('orderComplete.notFound')"
    />
  </div>

  <div class="v-order-complete-center v-text-center">
    <arora-button
      class-name="v-btn-lg v-submit-button"
      :label="translate('orderComplete.goToMenu')"
      data-test-id="submit-order-button"
      @click="goToMenu"
    />
  </div>

  <div
    class="v-d-flex v-flex-row v-align-items-center v-mb-md"
    v-if="
      appConfig.RestaurantSettingsPreRun.NeedToDisplayDeliveryDriverCoordinates &&
      orderInfoStatus.Status <= OrderStatus.R_Delivering &&
      !isSmall
    "
  >
    <span
      class="v-title__map-courier v-font-weight-600 v-mr-xs"
      v-html="translate('mapsCourier.titleMapCourier')"
    />

    <common-popover
      :delay="100"
      placement="top"
      v-if="orderInfoStatus.Status < OrderStatus.R_Delivering"
    >
      <template #hoverable>
        <span class="v-icon v-icon__24 v-primary-color__svg">
          <icon-old-general-information-circle class="v-w-100 v-img-fluid" />
        </span>
      </template>
      <template #content>{{ translate('mapsCourier.helpForCourier') }} </template>
    </common-popover>
  </div>

  <maps-courier
    v-if="
      appConfig.RestaurantSettingsPreRun.NeedToDisplayDeliveryDriverCoordinates &&
      orderInfo?.Latitude &&
      orderInfo?.Longitude &&
      orderInfoStatus.Status <= OrderStatus.R_Delivering &&
      !isSmall
    "
    :courier-latitude="orderInfoStatus.CourierLocation?.Latitude"
    :courier-longitude="orderInfoStatus.CourierLocation?.Longitude"
    :address-latitude="orderInfo?.Latitude"
    :address-longitude="orderInfo?.Longitude"
    :delivery-terminal="orderInfo?.TargetTerminalID"
  />
</template>

<script setup lang="ts">
import type { OrderInfo, OrderInfoStatus, OrderPointsInfo } from '~types/clientStore'

import { type GUID, useCommon, useWindowSize } from '@arora/common'

import { Guid, OrderStatus, OrderType } from '~api/consts'

const { orderId = Guid.Empty } = defineProps<{
  orderId?: GUID
}>()

type orderStatusGeneral = 'error' | 'not-integrated' | 'success' | 'waiting' | null

const { numeric, sanitize, translate } = useI18nSanitized()

const clientStore = useClientStore()

const { isSmall } = useWindowSize()
const { stringIsNullOrWhitespace } = useCommon()
const { phoneBeautified } = usePhoneNumber()
const { fromMillisInZone } = useDateTime()
const appConfig = useAppConfig()

const orderInfo = ref<OrderInfo | null>(null)
const loaded = ref<boolean>(false)

const orderInfoStatus = ref<OrderInfoStatus>({
  Number: '',
  Status: OrderStatus.Undefined,
  StatusTitle: null,
  TipsLink: ''
})

const orderStatusBlock = ref<orderStatusGeneral>(null)

const averageTimeWithDelayMs = computed<number>(() => {
  return (
    ((orderInfo.value?.AverageTimeSec ?? 0) + (orderInfo.value?.DelayTimeSec ?? 0)) *
    1000
  )
})
const warningText = computed<string>(() => {
  const result: string[] = []

  if (translate('orderComplete.warningText').length > 0) {
    result.push(translate('orderComplete.warningText'))
  }

  if (
    isDelivery.value &&
    translate('orderComplete.warningTextDelivery').length > 0
  ) {
    result.push(translate('orderComplete.warningTextDelivery'))
  }
  if (
    isSelfService.value &&
    translate('orderComplete.warningTextSelfService').length > 0
  ) {
    result.push(translate('orderComplete.warningTextSelfService'))
  }

  if (
    orderInfo.value?.RealOrderedCallback &&
    translate('orderComplete.warningTextCallback').length > 0
  ) {
    result.push(translate('orderComplete.warningTextCallback'))
  }
  if (
    !orderInfo.value?.RealOrderedCallback &&
    translate('orderComplete.warningTextNoCallback').length > 0
  ) {
    result.push(translate('orderComplete.warningTextNoCallback'))
  }

  return result.length > 0 ? result.join(' \n') : ''
})
const orderCompleteAppsBackground = computed<string>(() => {
  return appConfig.VueSettingsPreRun.OrderCompleteAppsBackground.Path.replaceAll(
    '\\',
    '/'
  )
})
const orderCompleteSocialBackground = computed<string>(() => {
  return appConfig.VueSettingsPreRun.OrderCompleteSocialBackground.Path.replaceAll(
    '\\',
    '/'
  )
})
const orderCompleteAdditionalBackground = computed<string>(() => {
  return appConfig.VueSettingsPreRun.OrderCompleteAdditionalBackground.Path.replaceAll(
    '\\',
    '/'
  )
})
const pointsAccrual = computed<number>(() => {
  return (
    orderInfo.value?.Points?.find(
      (pointsInfo: OrderPointsInfo) => pointsInfo.Accrual
    )?.Sum ?? 0
  )
})
const pointsWithdraw = computed<number>(() => {
  return (
    orderInfo.value?.Points?.find(
      (pointsInfo: OrderPointsInfo) => !pointsInfo.Accrual
    )?.Sum ?? 0
  )
})
const isDelivery = computed<boolean>(() => {
  return orderInfo.value?.OrderType === OrderType.CourierDelivery
})
const isSelfService = computed<boolean>(() => {
  return orderInfo.value?.OrderType === OrderType.NoShipment
})
const isInHall = computed<boolean>(() => {
  return orderInfo.value?.OrderType === OrderType.InHall
})
const deliveryTime = computed<string>(() => {
  const utcTime = orderInfo.value?.DeliveryTime?.UTCTime ?? 0
  const utcTimeRanged = orderInfo.value?.DeliveryTime?.UTCTimeRangeEnd ?? 0

  const parsed = fromMillisInZone(
    utcTime * 1000,
    appConfig.RestaurantSettingsPreRun.GMT
  )

  if (utcTime === utcTimeRanged) {
    return parsed.diffNow('days').days === 0
      ? translate('orderComplete.deliveryTimeToday', {
          time: parsed.toLocaleString({ hour: '2-digit', minute: '2-digit' })
        })
      : translate('orderComplete.deliveryTime', {
          date: parsed.toLocaleString({ day: '2-digit', month: 'short' }),
          time: parsed.toLocaleString({ hour: '2-digit', minute: '2-digit' })
        })
  }

  const parsedRanged = fromMillisInZone(
    utcTimeRanged * 1000,
    appConfig.RestaurantSettingsPreRun.GMT
  )

  return parsed.diffNow('days').days === 0
    ? translate('orderComplete.deliveryTimeTodayRanged', {
        end: parsedRanged.toLocaleString({ hour: '2-digit', minute: '2-digit' }),
        start: parsed.toLocaleString({ hour: '2-digit', minute: '2-digit' })
      })
    : translate('orderComplete.deliveryTimeRanged', {
        date: parsed.toLocaleString({ day: '2-digit', month: 'short' }),
        end: parsedRanged.toLocaleString({ hour: '2-digit', minute: '2-digit' }),
        start: parsed.toLocaleString({ hour: '2-digit', minute: '2-digit' })
      })
})

let isFirstLoad = true
let statusUpdateTimer: ReturnType<typeof setTimeout> | null = null

onMounted(async () => {
  orderInfo.value = await clientStore.loadOrderInfo(orderId)

  loaded.value = true

  if (orderInfo.value && !appConfig.VueSettingsPreRun.OrderCompleteHideStatus) {
    refreshWithNewStatus(orderInfo.value)
    scheduleNextUpdate(true)
  }
})

onUnmounted(() => {
  if (statusUpdateTimer) {
    clearTimeout(statusUpdateTimer)
  }
})

function openTipsLink(): void {
  navigateTo(orderInfoStatus.value?.TipsLink ?? '/', {
    external: true,
    open: {
      target: '_blank'
    }
  })
}

function updateStatus(): void {
  clientStore.refreshOrderStatus(orderId).then((newStatus: OrderInfoStatus) => {
    refreshWithNewStatus(newStatus)
    //theoretically we can stop refreshing status when it's final,
    //but we need to define which status codes are final
    scheduleNextUpdate(false)
  })
}

function scheduleNextUpdate(isInitial: boolean): void {
  if (statusUpdateTimer) {
    clearTimeout(statusUpdateTimer)
  }

  const refreshInterval = isInitial
    ? 5000
    : (orderInfoStatus.value.Status < OrderStatus.R_New && 5000) || 50000

  statusUpdateTimer = setTimeout(updateStatus, refreshInterval)

  if (isFirstLoad) {
    isFirstLoad = false
  }
}

function goToMenu(): void {
  navigateTo(appConfig.VueSettingsPreRun.Links.DefaultMenuGroup)
}

function print(): void {
  window.print()
}

function refreshWithNewStatus(statusInfo: OrderInfoStatus): void {
  orderInfoStatus.value = statusInfo

  switch (statusInfo.Status) {
    case OrderStatus.Sending:
    case OrderStatus.Sended:
    case OrderStatus.PaymentProcessing:
    case OrderStatus.PaymentReadyForProcessing:
    case OrderStatus.Saved:
    case OrderStatus.SavedLocally:
    case OrderStatus.Paid:
    case OrderStatus.ReadyToSend: {
      orderStatusBlock.value = 'waiting'
      break
    }
    case OrderStatus.R_NotConfirmed:
    case OrderStatus.R_New:
    case OrderStatus.R_Viewed:
    case OrderStatus.R_Cooking:
    case OrderStatus.R_Delivering:
    case OrderStatus.R_Ready:
    case OrderStatus.R_Close:
    case OrderStatus.Finished: {
      orderStatusBlock.value = 'success'
      break
    }
    case OrderStatus.DeletedAutomatically: {
      orderStatusBlock.value = 'not-integrated'
      break
    }
    default: {
      orderStatusBlock.value = 'error'
    }
  }
}
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-order-complete {
  display: grid;
  width: 100%;
  grid-template-columns: 5fr 7fr;

  &.v-order-complete__column {
    grid-template-columns: 100%;
    align-self: center;

    .v-order-complete-left {
      display: grid;
      gap: 10px;
      grid-template-columns: 2fr 3fr;
      justify-items: center;

      .v-order-complete-card-warning {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;

        @include mixins.sm {
          grid-row-start: auto;
        }
      }

      @include mixins.sm {
        grid-template-columns: 100%;
      }
    }

    .v-order-complete-right {
      display: grid;
      grid-template-columns: 100%;
      justify-items: center;
    }
  }

  .v-order-complete-left {
    padding: 0 15px;
    @include mixins.xs {
      padding: 0;
    }
  }

  .v-order-complete-right {
    padding: 0 15px;
    @include mixins.xs {
      padding: 0;
    }
  }

  @include mixins.md {
    padding: 20px 0 10px;
    grid-template-columns: 100%;
  }
}

.v-order-complete-center {
  padding-bottom: 60px;

  .v-btn {
    display: inline-block;
  }
}

.v-order-complete-card {
  border: 1px solid variables.$Mono50;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$CardShadow;
  width: 100%;
  margin-bottom: 1.75rem;

  background: rgba(125, 125, 125, 0.1);
  padding: 24px;
  font-size: 1rem;
  &--main-first {
    background: variables.$Background;
    border: 1px solid variables.$Mono50;
    border-radius: variables.$BorderRadius;
    padding: 30px;
    font-size: 1rem;
    box-shadow: variables.$CardShadow;
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include mixins.sm {
      padding: 16px;
    }
  }

  &--apps {
    background-size: 100% auto;
    aspect-ratio: 3/1;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;

    display: flex;
    justify-content: center;
    align-items: center;

    .v-apps {
      width: 65%;

      svg {
        width: 100%;
        height: auto;
      }
    }

    @include mixins.sm {
      grid-row-start: auto;
    }
  }

  &--social {
    background-size: 100% auto;
    aspect-ratio: 3/1;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;

    display: flex;
    justify-content: center;
    align-items: center;

    .v-social-networks-horizontal {
      width: 65%;
      margin-top: 60px;

      @include mixins.sm {
        margin-top: 15px;
      }

      .v-social-network-divider {
        padding: 30px;

        @include mixins.sm {
          padding: 7px;
        }
      }
    }

    @include mixins.sm {
      grid-row-start: auto;
    }
  }

  @include mixins.sm {
    padding: 12px;
  }

  &-social-compact {
    background-size: 100% auto;
    aspect-ratio: 3/1;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .v-social-networks-horizontal {
      width: 75%;
      margin-top: 60px;

      @include mixins.sm {
        margin-top: 15px;
      }

      .v-social-network-divider {
        padding: 10px;

        @include mixins.sm {
          padding: 0;
        }
      }
    }

    @include mixins.sm {
      grid-row-start: auto;
    }
  }
}

.v-order-complete-items-wrapper {
  width: 100%;
}

.v-order-complete-card-main-second {
  background: variables.$Background;
  border: 1px solid variables.$Mono50;
  border-radius: variables.$BorderRadius;
  padding: 30px;
  font-size: 1rem;
  box-shadow: variables.$CardShadow;
  flex-grow: 1;
  margin-bottom: 20px;

  ul {
    padding: 0;
  }

  &.v-order-complete__column {
    width: 500px;

    @include mixins.sm {
      width: 100%;
    }
  }
}

.v-order-complete-status {
  cursor: default;
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 20px;
  flex: 0 0 calc(100% - 60px);
  max-width: calc(100% - 60px);
}

.v-order-complete-status-text {
  margin-bottom: 1.1rem;
  font-size: 1.15rem;
}

.v-order-complete-info-title {
  cursor: default;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 12px;
}

.v-order-complete-info {
  cursor: default;
  font-size: 1.15rem;
  margin-bottom: 10px;

  &-personal {
    cursor: default;
    font-size: 1.15rem;
    margin-bottom: 10px;
    display: flex;
  }

  &-description {
    font-weight: 600;
    margin-right: 10px;
  }

  &-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
}

.v-print-order {
  .v-icon__18 {
    color: variables.$Mono1000;
    opacity: 0.7;
  }
}
.v-title__map-courier {
  font-size: 1.7rem;
}
</style>
